export default {
  state: {},
  getters: {},
  actions: {
    getPermissions(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/app/permission")
        .then((res) => {
          let permissions = {
            student: {
              access: false,
            },
            purchase: {
              access: false,
            },
            dashboard: {
              student_report: false,
              purchase_report: false,
              edit: false,
              delete: false,
            },
            report: {
              course_completion_access: false,
              sales_over_view_access: false,
              teacher_sales_over_view_access: false,
            },
          };
          res.data.permissions.forEach((permission) => {
            // Report Permission
            if (permission.slug == "course.sales.over.view.report.access") {
              permissions.report.sales_over_view_access = true;
            }
            if (permission.slug == "teacher.sales.over.view.report.access") {
              permissions.report.teacher_sales_over_view_access = true;
            }
            // Division Permission
            if (permission.slug == "dashboard.student.report.access") {
              permissions.dashboard.student_report = true;
            }
            if (permission.slug == "dashboard.purchase.report.access") {
              permissions.dashboard.purchase_report = true;
            }
            if (permission.slug == "student.completion.report.access") {
              permissions.report.course_completion_access = true;
            }

            if (permission.slug == "student.access") {
              permissions.student.access = true;
            }
            if (permission.slug == "purchase.access") {
              permissions.purchase.access = true;
            }
          });
          content.commit("getPermissions", permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
